import api from "@/api";
import Button from "@/components/Button";
import Pagination from "@/components/Pagination";
import { CouponParam } from "@/models/coupon";
import { ButtonListRow, PaginationRow, TableRow } from "@/models/type";
import { getFile, typeWatch } from "@/utils";
import { ElImage, ElMessageBox, ElNotification } from "element-plus";
import { ElFormItemContext } from "element-plus/lib/el-form";
import { defineComponent, h, reactive, Ref, ref, watch, nextTick, onMounted } from "vue";

const Voucher = defineComponent({
  setup() {
    const img = ref()
    const form = ref()
    const title = ref('')
    const tableData = ref([])
    const detailBool = ref(false)
    const loading = ref(false)

    const type = ref('')

    watch(type, (value, oldValue): void => {
      title.value = typeWatch(value)
      switch(value) {
        case 'add':
          break
        default:
      }
    })

    const url = ref('')
    const total = ref(0)
    const pagination = ref({})
    const srcList: Ref<Array<string>> = ref([])
    const ids: Ref<Array<number>> = ref([])

    const formRules: any = {}

    let voucherForm = reactive({
      img: '',
      name: '',
      point: 0,
      amount: 0,
      useType: 0,
    })
    const tableList: Array<TableRow> = [
      { label: '序号', slot: {
        default: (scope: any) => <span>{scope.$index + 1}</span>
      } },
      { label: '图片', slot: {
        default: (scope: any) => <img class="mall-img pointer" src={ scope.row.img } alt="" onClick={() => imgShow(scope.row.img)} />
      } },
      { label: '名称', prop: 'name' },
      { label: '可抵现金', prop: 'amount' },
      { label: '积分', prop: 'point' },
      { label: '操作', slot: {
        default: (scope: any): JSX.Element => <div>
          <span class="span-button-primary" onClick={ () => voucherEdit(scope.row) }>编辑</span>
          <span class="span-button-danger" onClick={ () => voucherDelete(scope.row.id) }>删除</span>
        </div>
      } }
    ]
    const buttonList: Array<ButtonListRow> = [
      { title: '添加', icon: 'el-icon-plus', click: 'voucherAdd' },
      { title: '删除', icon: 'el-icon-minus', type: 'danger', click: 'voucherDelete' },
    ]

    function imgShow(imgurl: string): void {
      url.value = imgurl
      srcList.value = tableData.value.map((item: CouponParam) => item.img) as string[] // 将类型为图片的给srcList
      imgViewerShow()
    }

    function buttonClick(click: string): void {
      switch(click) {
        case 'voucherAdd':
          voucherAdd()
          break
        case 'voucherDelete':
          voucherDelete()
          break
        default:
      }
    }

    function getList(): void {
      nextTick(() => {
        const __pagination = pagination.value as any
        const { pageNum, pageSize } = __pagination.paginationObj as PaginationRow
        api.couponList({pageNum, pageSize}).then(res => {
          tableData.value = res.data.list
          total.value = res.data.total
        })
      })
    }

    const detailClose = (): void => {
      type.value = ''
      detailBool.value = false
      voucherForm = reactive({
        img: '',
        name: '',
        point: 0,
        amount: 0,
        useType: 0
      })
    }

    const detailDom = (): JSX.Element => {

      const slots = {
        // 对话框底部元素
        footer: () => (
          <span class="dialog-footer">
            <button class="dialog-footer-btn dialog-footer-btn-l" onClick={ detailClose }>取 消</button>
            <button class="dialog-footer-btn dialog-footer-btn-r" onClick={ submit }>确 定</button>
          </span>
        )
      }

      return <el-dialog
        title={ `${title.value}代金券` }
        v-model={ detailBool.value }
        width="30%"
        before-close={ detailClose }
        v-slots={ slots }
        custom-class="resource-dialog"
      >
        <el-form
          model={ voucherForm }
          ref={ form }
          rules={ formRules }
          label-width="120px"
          class="el-form-tao"
        >
          <el-form-item label="名称" prop="name">
            <el-input v-model={ voucherForm.name } placeholder="请输入代金券名称" clearable />
          </el-form-item>
          <el-form-item label="所需积分" prop="point">
            <el-input v-model={ voucherForm.point } placeholder="请输入代金券所需积分" clearable />
          </el-form-item>
          <el-form-item label="可抵现金" prop="amount">
            <el-input v-model={ voucherForm.amount } placeholder="请输入代金券可抵现金" clearable />
          </el-form-item>
          <el-form-item label="图片" prop="img">
              { loading.value
                ? <span class="el-form-tao-btn" >上传中...</span>
                : <span class="el-form-tao-btn" onClick={ uploadFile }>{ voucherForm.img ? '重新上传' : '上传' }</span>}
              {voucherForm.img && <span class="el-form-tao-view" onClick={imgView} >预览</span>}
          </el-form-item>
        </el-form>
      </el-dialog>
    }

    function submit(): void {
      const __form = form.value as ElFormItemContext
      __form.validate(v => {
        if(v) {
          const form = Object.assign({}, voucherForm) as CouponParam
          type.value === 'add'
            ? submitAdd(form)
            : submitEdit(form)
        } else {
          return false
        }
      })
    }

    function submitAdd(form: CouponParam): void {
      delete form.id
      api.couponCreate(form).then(res => {
        ElNotification({
          type: 'success',
          title: '成功',
          message: '添加成功'
        })
        detailClose()
        getList()
      })
    }

    function submitEdit(form: CouponParam): void {
      api.couponUpdate(form).then(() => {
        ElNotification({
          type: 'success',
          title: '成功',
          message: '编辑成功'
        })
        detailClose()
        getList()
      })
    }

    function uploadFile(): void {
      getFile(files => {
        if(!files.length) {
          return
        }
        const formData = new FormData()
        formData.append('file', files[0])
        loading.value = false
        api.commonOssUpload(formData).then(res => {
          voucherForm.img = res.data
          ElNotification({
            title: '成功',
            type: 'success',
            message: `上传图片成功`
          })
          loading.value = false
        })
      }, 'MALL', 'image/*')
    }

    function imgView(): void {
      const newUrl = voucherForm.img as string
      url.value = newUrl
      srcList.value = [newUrl]
      imgViewerShow()
    }

    function imgViewerShow(): void {
      const __img =  img.value as any
      __img.showViewer = true
    }

    function voucherAdd(): void {
      type.value = 'add'
      detailBool.value = true
    }
    function voucherEdit(row: any): void {
      voucherForm = reactive(Object.assign({}, row))
      type.value = 'edit'
      detailBool.value = true
    }

    function voucherDelete(id?: number): void {
      const newIds = id ? [id] : ids.value
      if(!(newIds.length)) {
        ElNotification({
          type: 'warning',
          title: '请选择要删除的代金券'
        })
        return
      }
      ElMessageBox.confirm('确定删除该代金券？', '删除代金券', {
        type: 'warning'
      }).then(() => {
        api.couponDelete(newIds).then(res => {
          ElNotification({
            type: 'success',
            title: '删除',
            message: '删除成功'
          })
          getList()
        })
      }).catch(() => {
        return false
      })
    }

    function selectionChange(row: any): void {
      ids.value = row.map(item => item.id)
    }

    function imgViewerDom(): JSX.Element {
      return h(ElImage, {
        ref: img, // 这里必须是ref变量 不可以是字符串 vue 2.0 版本是字符串
        style: {
          width: 0,
          height: 0,
          zIndex: 54199
        },
        src: url.value,
        previewSrcList: srcList.value
      })
    }

    function tableDom(): JSX.Element {
      return (
        <el-table
          data={ tableData.value }
          onSelectionChange={ selectionChange }
        >
          <el-table-column type="selection" width="50px" />
          {tableList.map(item => (
            <el-table-column
              label={ item.label }
              prop={ item.prop }
              align="center"
              width={ item.width }
              v-slots={ item.slot }
            />
          ))}
        </el-table>
      )
    }

    onMounted(() => {
      getList()
    })

    return () => <div class="mall-integral-content">
      <Button list={ buttonList } onClick={ buttonClick } />
      {tableDom()}
      <Pagination ref={pagination} total={total.value} onCurrentChange={getList} onSizeChange={getList} />
      {imgViewerDom()}
      {detailBool.value && detailDom()}
    </div>
  }
})

export default Voucher
