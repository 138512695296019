import api from "@/api";
import ArticleInfo from "@/components/ArticleInfo";
import Card, { CardTitle } from "@/components/Card";
import ImageCell, { FormRule } from "@/components/ImageCell";
import { ProductParam } from "@/models/product";
import { Response } from "@/models/request";
import { OptionsRow } from "@/models/type";
import { getFile } from "@/utils";
import { ElCol, ElDivider, ElFormItem, ElNotification, ElRow } from "element-plus";
import { ElFormItemContext } from "element-plus/lib/el-form";
import { h } from "vue";
import { Vue } from "vue-class-component";
import Commodity from ".";
import '../commodity/index.scss'
export default class ThingDetail extends Vue {

  public commodityForm: ProductParam = {
    type: 1,
    artistId: 0,
    artistImg: '',
    artistIntroduce: '',
    coverImg: '',
    mainImg: '',
    albumPics: '',
    name: '',
    productCategoryId: '',
    description: '',
    productSn: '',
    originalPrice: 0,
    detailHtml: '',
    skuStockList: []
  }

  uploadLoading = false

  private formRule: any = {}

  productTypeList: Array<OptionsRow> = []

  public articleImgBool = false
  private formList: Array<any> =  []

  goBack(): void {
    const __parent = this.$parent as Commodity
    __parent.type = ''
    this.commodityForm = {
      type: 1,
      artistId: 0,
      artistImg: '',
      artistIntroduce: '',
      coverImg: '',
      mainImg: '',
      albumPics: '',
      name: '',
      productCategoryId: '',
      description: '',
      productSn: '',
      originalPrice: 0,
      detailHtml: '',
      skuStockList: []
    }
  }

  private typeSwitch(switchRow: any): JSX.Element | null {
    const { type } = switchRow
    switch(type) {
      case 'input':
        return <el-input
          v-model={ this.commodityForm[switchRow.prop] }
          type={switchRow.inputType || 'text'}
          placeholder={switchRow.placeholder}
          showWordLimit={true}
          disabled={switchRow.disabled}
          maxlength={switchRow.inputType === 'textarea' ? 240 : null}
          clearable
        />
      case 'select':
        return <el-select v-model={ this.commodityForm[switchRow.prop] } placeholder={ switchRow.placeholder || '请选择' } clearable style="width: 100%" filterable>
        {switchRow?.options.map((i:any) => (
          <el-option
            key={ i.value }
            label={ i.name }
            value={ i.value }
          />
        ))}
      </el-select>
      case 'number':
        return <el-input-number
          v-model={ this.commodityForm[switchRow.prop] }
          placeholder={switchRow.placeholder}
          style="width: 100%"
          min={0}
          disabled={switchRow.disabled}
          clearable
        />
      default:
        return null
    }
  }

  getData(){
    Promise.all([
      api.productCateListGorywithChildren()
    ]).then(res => {
      this.productTypeList = res[0].data.map(item => ({ name: item.name, value: item.id }))
      this.formList = [
        { label: '商品名称', prop: 'name', type: 'input', lg: 12, sm: 24, placeholder: '请输入商品名称' },
        { label: '商品类型', prop: 'productCategoryId', type: 'select', options: this.productTypeList, lg: 12, sm: 24, placeholder: '请选择商品类型' },
        { label: '商品描述', prop: 'description', type: 'input', lg: 24, sm: 24, inputType: 'textarea', placeholder: '请输入商品描述', className: 'mall-info-textarea' },
        { label: '商品货号', prop: 'productSn', type: 'input', lg: 12, sm: 24, placeholder: '请输入商品货号' },
        { label: '商品编号', prop: 'id', disabled: true, type: 'input', lg: 12, sm: 24, placeholder: '商品编号系统自动生成' },
        { label: '所需积分', prop: 'originalPrice', type: 'number', lg: 12, sm: 24, placeholder: '请输入赠送积分' },
      ]
    })
  }

  private formDom(): JSX.Element {
    return h(ElRow, { gutter: 50 }, () => this.formList.map(item => h(ElCol, {
        lg: item.lg,
        sm: item.sm,
        class: item.className
      }, () => h(ElFormItem, {
          label: item.label,
          prop: item.prop
        }, () => this.typeSwitch(item))
    )))
  }

  skuListDom(): JSX.Element {
    const slotTitle = {
      prefix: () => <span>名称：</span>
    }
    const slotNo = {
      prefix: () => <span>货号：</span>
    }
    const slotStock = {
      prefix: () => <span>库存：</span>
    }
    return <div class="mall-sku">
      <div class="mall-sku-title">子商品</div>
      {
        this.commodityForm.skuStockList.map((item, index) => <el-row gutter={50}>
          <el-col span={3}>
          <el-form-item prop={'skuStockList.' + index + '.pic'}>
            {
              item.pic
                ? <img class="mall-sku-pic" onClick={() => this.uploadPic(index)} src={item.pic} alt="" />
                : <div class="mall-sku-pic" onClick={() => this.uploadPic(index)} />
            }
          </el-form-item>
          </el-col>
          <el-col span={6} prop={'skuStockList.' + index + '.title'}>
            <el-form-item>
              <el-input v-model={item.title} v-slots={slotTitle} placeholder="请输入名称" clearable/>
            </el-form-item>
          </el-col>
          <el-col span={6} prop={'skuStockList.' + index + '.skuCode'}>
            <el-form-item>
              <el-input v-model={item.skuCode} v-slots={slotNo} placeholder="请输入货号" clearable/>
            </el-form-item>
          </el-col>
          <el-col span={6}>
            <el-form-item prop={'skuStockList.' + index + '.stock'}>
              <el-input v-model={item.stock} v-slots={slotStock} placeholder="请输入库存" clearable/>
            </el-form-item>
          </el-col>
          <el-col span={3}>
            <el-form-item>
              <span class="pointer" style="color: #F05549" onClick={() => this.skuDel(index)}>删除</span>
            </el-form-item>
          </el-col>
        </el-row>)
      }
      <div class="mall-sku-push" onClick={this.skuAdd}>
        <i class="el-icon-plus" />
        <span>添加</span>
      </div>
    </div>
  }

  uploadPic(index: number): void {
    getFile(files => {
      if(!files.length) {
        return
      }
      const formData = new FormData()
      formData.append('file', files[0])
      api.commonOssUpload(formData).then(res => {
        this.commodityForm.skuStockList[index].pic = res.data
      })
    }, 'MALL', 'image/*')
  }

  skuDel(index: number): void {
    this.commodityForm.skuStockList.splice(index, 1)
  }
  skuAdd(): void {
    const obj = {
      pic: '',
      title: '',
      skuCode: '',
      stock: 0,
      price: 0,
    }
    this.commodityForm.skuStockList.push(obj)
  }

  onChangeImg(form: FormRule): void {
    this.commodityForm.coverImg = form.coverImg
    this.commodityForm.mainImg = form.mainImg
    this.commodityForm.albumPics = form.otherImg
  }

  infoDom(): JSX.Element {
    return h('div', {
      class: 'mall-info'
    }, [
      h(this.formDom()),
      h(this.skuListDom()),
      h(ImageCell, {
        ref: 'image_cell',
        coverImg: this.commodityForm.coverImg,
        mainImg: this.commodityForm.mainImg,
        otherImg: this.commodityForm.albumPics,
        sizeType: 'mall',
        onChangeImg: this.onChangeImg
      }),
      h(this.detailDom()),
      h(this.submitDom())
    ])
  }

  detailDom(): JSX.Element {
    return <div class="mall-d">
      <div class="mall-sku-title">详情页</div>
      {
        this.uploadLoading
          ? <div class="mall-d-btn">上传中...</div>
          : <div class="mall-d-btn" onClick={this.uploadDetail}>上传</div>
      }
      {this.commodityForm.detailHtml && this.commodityForm.detailHtml.split(',').map((item, index) => <div class="mall-d-end">
        <img src={item}/>
        <i class="el-icon-error" onClick={() => this.picDel(index)}></i>
      </div>)}
    </div>
  }

  picDel(index): void {
    const arr = this.commodityForm.detailHtml?.split(',') as string []
    arr.splice(index, 1)
    this.commodityForm.detailHtml = arr.join(',')
  }

  uploadDetail(): void {
    this.uploadLoading = true
    getFile(files => {
      const requestData = []
      files.forEach(item => {
        const formData = new FormData()
        formData.append('file', item)
        requestData.push(api.commonOssUpload(formData) as never)
      })
      Promise.all(requestData).then(res => {
        const imgArr: string[] = []
        res.forEach((i: Response) => {
          imgArr.push(i.data)
        })
        this.commodityForm.detailHtml = this.commodityForm.detailHtml ? `${this.commodityForm.detailHtml},${imgArr.join(',')}` : imgArr.join(',')
        this.uploadLoading = false
      })
    }, '', 'image/*', true)
  }

  submitDom(): JSX.Element {
    return <div class="mall-submit">
      <div onClick={this.submit}>保存</div>
      <div onClick={this.goBack}>取消</div>
    </div>
  }

  submit(): void {
    const __form = this.$refs.form as ElFormItemContext
    const __parent = this.$parent as Commodity

    const __imageCell = this.$refs.image_cell as ImageCell
    const __articleInfo = this.$refs.article_info as ArticleInfo

    __form.validate(v => {
      if(v) {
        const obj = Object.assign({}, this.commodityForm,  __imageCell.form)
        obj.artistId =  __articleInfo.form.articleId
        obj.artistImg =  __articleInfo.form.articleImg
        obj.artistIntroduce =  __articleInfo.form.articleIntroduce
        obj.artistName =  __articleInfo.form.articleName
        obj.skuStockList.forEach(item => {
          item.price = obj.originalPrice
        })
        obj.productCategoryName = this.productTypeList.find(item => item.value === obj.productCategoryId)?.name
        __parent.type === 'add'
          ? this.submitAdd(obj)
          : this.submitEdit(obj)
      } else {
        return false
      }
    })
  }
  submitAdd(form: ProductParam): void {
    api.productCreate(form).then(res => {
      ElNotification({
        type: 'success',
        title: '成功',
        message: '添加成功'
      })
      this.goBack()
    })
  }
  submitEdit(form: ProductParam): void {
    api.productUpdate(form).then(res => {
      ElNotification({
        type: 'success',
        title: '成功',
        message: '编辑成功'
      })
      this.goBack()
    })
  }

  created(): void {
    this.getData()
  }

  render(): JSX.Element {
    return (
      <el-form
        ref="form"
        class="mall"
        model={ this.commodityForm }
        rules={ this.formRule }
      >
        <Card title="艺术家信息" back onGoBack={ this.goBack }>
          {h(ArticleInfo, {
            ref: 'article_info',
            articleId: this.commodityForm?.artistId,
            articleImg: this.commodityForm?.artistImg,
            articleIntroduce: this.commodityForm?.artistIntroduce,
            isInput: true
          }, () => h(ElDivider, {
            class: 'mall-divider'
          }))}
          {CardTitle('商品信息')}
          {this.infoDom()}
        </Card>
      </el-form>
    )
  }
}

