import Search from "@/components/Search";
import { defineComponent, toRefs } from "vue";

const Thing = defineComponent({
  props: {
    searchList: {
      type: Array,
      default: () => []
    },
    onSearch: Function
  },
  emits: ['search'],
  setup(props, { emit }) {
    const { searchList } = toRefs(props)

    function search(row: never): void {
      emit('search', row)
    }

    return (): JSX.Element => <Search list={ searchList.value } onSearch={ search }/>
  }
})

export default Thing
